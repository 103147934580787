import React, { useRef, useState, useEffect } from 'react';
import "../assets/scss/landingPage.scss";
import {ReactComponent as Logo} from '../assets/images/logo-white.svg';
import {ReactComponent as Line} from '../assets/images/line.svg';
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from 'react-loading-icons'

const LandingPage = () => {
    const form = useRef();
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(0);
    const [width, setWidth] = useState(0);
    const [count, setCount] = useState(0);

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        emailjs
          .sendForm(
            "service_lt65frb",
            "template_q5kbq25",
            form.current,
            "bdAzCQJVNzoOlPKi1"
          )
          .then(
            (result) => {
              toast.success("Message Sent successfully!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              document.getElementById("myForm").reset();
              setLoading(false);
            },
            (error) => {
              toast.error("Ops Message not Sent!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setLoading(false);
            }
          );
      };

      useEffect(() => {
        setValue(62);
        setWidth(280);

        document.getElementById("progress-bar").style.transition="all 1s ease";
      },[]);

      useEffect(() => {
          if(count < 62) 
        setTimeout(() => {
            setCount(count +1)
        }, 15)
      }, [count])

    return(
        <div className="landing-page">
            <Logo className="logo"/>
            <div className="content">
                <p>OUR WEBSITE IS UNDER CONSTRUCTION</p>
                <h3>COMING SOON</h3>
                   <div className="progress-div" style={{width:`${width}px`}}>
                        <div id="progress-bar" style={{ width: `${value}%` }} className="progress"/>
                    </div>
                <p className="percent">{count}%</p>

            </div>
            <div className="footer">
                <form id="myForm" className="contact" ref={form} onSubmit={sendEmail}>
                    <input type="text" name="user_email" placeholder="Please write your email address" required/>
                   {loading? <div className='button loading'> <Oval height="26px"/></div>
                   :
                    <input type="submit" className="button" value="Notify Me" style={{borderRadius:"3px"}} />}
                </form>
                <p>Contact us <Line/>  hello@factoryofinnovations.com</p>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
            </div>
        </div>
    )
}

export default LandingPage;